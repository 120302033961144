<template>
  <div>
  <div class='radius_bg'>
    <el-row style="margin-top: 10px">
        <el-form :model="searchCondition" :inline="true" size="small">
          <el-form-item>
            <el-date-picker
                v-model="ym"
                type="month"
                value-format="yyyy-MM"
                format="yyyy-MM"
                placeholder="选择月份" @change="changeMonth" style="width: 120px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <DeptSelectTree :searchCondition.sync='searchCondition' ref='deptTree' style='width: 360px'
                            :isType="'prize'" :isCheckStrictly="false"
                            @handleSelect='handleChangeDept'></DeptSelectTree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="changeSearch" icon='el-icon-search'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <LogPanel :infoIds="kpi_dept_id" buttonSize="small" ref='refLogPanel'
                      v-if="kpi_dept_id!==null" module-name='pa_kpi'></LogPanel>
          </el-form-item>
        </el-form>
    </el-row>
  </div>
    <div class="default-table">
      <el-table
          :data='dataList'
          border>
        <el-table-column
            prop="dept_name"
            label='项目组'
            align='center'
            >
        </el-table-column>
        <el-table-column
            prop="is_confirm"
            label='确认状态'
            align='center'
        >
          <template slot-scope="{row}" v-if="row.is_confirm">
            <h2 style="margin: 0;" :style="row.is_confirm === 0?{color: ''}:{color: '#0aab62'}">
              {{ row.is_confirm === 0 ? '未确认' : '已确认' }} </h2>
            <span>
              确认人:{{ row.confirm_by }}<br/>
              确认时间:{{ row.confirm_at }}
            </span>
          </template>
          <template  v-else>
            无状态
          </template>
        </el-table-column>
        <el-table-column
            label='目标'
            prop="target_month"
            align='center'
            :formatter="formatter"
            width='150'>
        </el-table-column>
        <el-table-column
            label='基础奖金包'
            prop="min_prize"
            align='center'
            :formatter="formatter"
            width='150'>
        </el-table-column>
        <el-table-column
            label='最高奖金包'
            prop="max_prize"
            align='center'
            :formatter="formatter"
            width='150'>
        </el-table-column>
        <el-table-column
            label='营收汇总'
            prop="income_total"
            :formatter="formatter"
            align='center'
            width='150'>
        </el-table-column>
        <el-table-column
            label='成本汇总'
            prop="cost_total"
            :formatter="formatter"
            align='center'
            width='150'>
        </el-table-column>
        <el-table-column
            label='实际利润'
            prop="real_profit"
            :formatter="formatter"
            align='center'
            width='150'>
        </el-table-column>
        <el-table-column
            label='目标完成率（%）'
            prop="done_rate"
            align='center'
            width='150'>
          <template slot="header">
            目标完成率（%）
            <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="目标完成率=实际利润/月目标">
              <i slot="reference" style="font-size: 12px;cursor: pointer;color: rgb(255, 49, 118)"
                 class="el-icon-info"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            label='系数'
            prop="factor"
            align='center'
            width='150'>
          <template slot="header">
            系数
            <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="系数：根据目标完成率所在区间确定">
              <i slot="reference" style="font-size: 12px;cursor: pointer;color: rgb(255, 49, 118)"
                 class="el-icon-info"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            label='奖金'
            prop="prize"
            align='center'
        >
          <template slot-scope="{row,column}">
            <editable-cell :show-input="false"
                           v-model="row['prize']"
                           v-if="userPermissions.indexOf('pa_kpi_prize__save') !== -1"
                           @change="editVal(row,column.property)" :is-input-number="true"
            >
            <span slot="content">
            {{ valFormat(row[column.property]) }}
              <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
            </span>
            </editable-cell>
            <span v-else>
            {{ valFormat(row[column.property]) }}
        </span>
          </template>
          <!--          <editable-cell :show-input="false" slot-scope="{row,column}"-->
          <!--                         v-model="row['prize']"-->
          <!--                         @change="editVal(row,column.property)" :is-input-number="true"-->
          <!--          >-->
          <!--            <span slot="content">-->
          <!--            {{ valFormat(row[column.property]) }}-->
          <!--              <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>-->
          <!--            </span>-->
          <!--          </editable-cell>-->
        </el-table-column>
      </el-table>
    </div>
    <el-row style="margin-top: 10px">
      <el-button size="medium" v-has-authority="'pa_kpi_target__confirm'" style="float: right;margin-left: 10px"
                 type="success"
                 @click="handleTarget">
        确认目标
      </el-button>
      <el-button size="medium" v-has-authority="'pa_kpi_prize__release'" style="float: right" type="primary"
                 @click="handleSubmit">
        发布奖金
      </el-button>
    </el-row>
    <div style="margin-top: 10px">
      <BonusTable    @init="changeSearch"   :infoIds="kpi_dept_id" ref="BonusTable" :searchCondition="searchCondition"></BonusTable>
    </div>
    <div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col>
        <span class="title">
          <i class="el-icon-info" style="font-size: 14px;color: #ff3176"></i>
          备注
        </span>
        </el-col>
        <el-col :span="6" style="margin-top: 10px">
          <table class="table">
            <thead>
            <tr>
              <th>利润规模</th>
              <th>基础奖金包</th>
              <th>最高</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: 1px solid red">
              <td>2W以内</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>2W(含)-10W</td>
              <td>{{ valFormat(10000) }}</td>
              <td>{{ valFormat(20000) }}</td>
            </tr>
            <tr>
              <td>10W(含)-20W</td>
              <td>{{ valFormat(20000) }}</td>
              <td>{{ valFormat(40000) }}</td>
            </tr>
            <tr>
              <td>20W(含)-40W</td>
              <td>{{ valFormat(25000) }}</td>
              <td>{{ valFormat(50000) }}</td>
            </tr>
            <tr>
              <td>40W(含)-60W</td>
              <td>{{ valFormat(30000) }}</td>
              <td>{{ valFormat(60000) }}</td>
            </tr>
            <tr>
              <td>60W(含)-80W</td>
              <td>{{ valFormat(35000) }}</td>
              <td>{{ valFormat(70000) }}</td>
            </tr>
            <tr>
              <td>80W(含)</td>
              <td>{{ valFormat(50000) }}</td>
              <td>{{ valFormat(100000) }}</td>
            </tr>
            <tr>
              <td>100W(含)</td>
              <td>{{ valFormat(60000) }}</td>
              <td>{{ valFormat(120000) }}</td>
            </tr>
            </tbody>

          </table>
        </el-col>
        <el-col :span="6" style="margin-top: 10px">
          <table class="table">
            <thead>
            <tr>
              <th>完成率</th>
              <th>系数</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>小于100%</td>
              <td>0</td>
            </tr>
            <tr>
              <td>100%（含）- 200%</td>
              <td>系数等于完成率</td>
            </tr>
            <tr>
              <td>大于等于200%</td>
              <td>2</td>
            </tr>
            </tbody>
          </table>
        </el-col>
        <el-col>
          <tip-list :tipsList="tipsList"/>
        </el-col>
      </el-row>

    </div>
  </div>

</template>

<script>

import {mapGetters} from "vuex";
import EmphasisProductsSchSave from "@/pages/emp_exp_product/emphasis-products/list/schedules/save.vue";
import EmphasisProductsSchList from "@/pages/emp_exp_product/emphasis-products/list/schedules/list.vue";
import BonusTable from "./component/performanceForm.vue";
import EditableCell from "@/components/EditableCell.vue";
import DeptSelectTree from "@/pages/mcn_performance_data/component/DeptSelectTree.vue";
import {numberFormat} from "@/utils/utils";
import TipList from "@/components/TipsList.vue";
import {numberToChinese} from "@/utils";
import LogPanel from "@/components/logs/LogPanel.vue";
import performanceForm from "@/pages/goal_tracking/component/performanceForm.vue";

export default {
  name: "PerformanceObjectives",
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  components: {
    performanceForm,
    LogPanel,
    TipList,
    DeptSelectTree,
    EditableCell,
    BonusTable,
    EmphasisProductsSchList,
    EmphasisProductsSchSave
  },
  data() {
    return {
      searchCondition: {year: null, dept_id: null, group_name: '', date_value: 1, type: 1, month: 1,},
      year: null,
      conditionStr: '',
      dataList: [],
      showEditIcon: true,
      ym: null,
      tipsList: [
        {title: '商务：采买', text: '营收数据的【商务营收】'},
        {title: '商务：全案', text: '营收数据的【保量收入】'},
        {title: '短视频佣金', text: '营收数据的【短视频佣金】'},
        {title: '直播佣金', text: '营收数据的【直播佣金】'},
        {title: '其他（特殊）', text: '营收数据的【直播坑位费】'},
        {title: '网红分成', text: '红人模型数据的【红人模型-网红分成】'},
        {title: 'MCN推广', text: '红人模型数据的【MCN推广】'},
        {title: '直播营销费', text: '红人模型数据的【直播投放金】'},
        {title: '费用报销', text: '红人模型数据的【报销明细】'},
        {title: '人工成本', text: '红人模型数据的【人工成本】'},
        {title: '商务支持团队', text: '红人模型数据的【商务支持团队费用】'},
        {title: '返点费用', text: '红人模型数据的【返点费用】'},
        {title: '办公租金装修费', text: '红人模型数据的【办公租金装修】'},
        {title: '管理服务费', text: '红人模型数据的【管理服务费】'},
        {title: '其他（特殊）', text: '暂无数据'},
      ],
      kpi_dept_id: null
    }
  },
  mounted() {
    this.searchCondition.date_value = this.$utils.getCurrentQuarter()
    this.defaultTime()
    this.changeOption()
  },
  methods: {
    changeOption() {
      this.$refs['deptTree'].show(this.searchCondition)
    },
    defaultTime() {
      const end = new Date();
      //年份选择：默认当月
      this.searchCondition.year = end.getFullYear()
      this.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
      this.searchCondition.month = end.getMonth() + 1

    },
    changeMonth() {
      let ymArr = this.ym.split('-')
      let month = parseInt(ymArr[1])
      let quarter = [1, 2, 3].includes(month) ? 1 : [4, 5, 6].includes(month) ? 2 : [7, 8, 9].includes(month) ? 3 : [10, 11, 12].includes(month) ? 4 : null

      this.searchCondition.year = parseInt(ymArr[0])
      this.searchCondition.month = parseInt(ymArr[1])
      this.searchCondition.date_value = quarter
      this.changeOption()

      this.getList()
    },
    changeSearch() {
      this.$refs.BonusTable.initList()
      this.getList()
    },
    async getList() {
      this.isLoading = true
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.dept_id) {
        const {list, page_info} = await this.$api.getprizeInfoGroup(searchCondition)
        this.dataList = list
        this.pagingData = page_info
      }
      this.isLoading = false


    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.dept_id) {
        condition.dept_id = this.searchCondition.dept_id
      }
      if (this.searchCondition.group_name) {
        condition.group_name = this.searchCondition.group_name
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.date_value) {
        condition.date_value = this.searchCondition.date_value
        condition.quarter = this.searchCondition.date_value
      }
      if (this.searchCondition.month) {
        condition.month = this.searchCondition.month
      }
      return condition
    },
    async editVal(row, colName) {
      // savePrizeInfoGroup
      let update = {
        ...this.searchCondition,
        date_value: this.searchCondition.month,
        [colName]: row[colName]
      }
      let data = await this.$api.savePrizeInfoGroup(update)
      if (!data) {
        this.$message.error('修改失败')
      }
    },
    async handleSubmit() {
      let data = {
        year: this.searchCondition.year,
        date_value: this.searchCondition.date_value,
        dept_id: this.searchCondition.dept_id,
        type: this.searchCondition.type,
        month: this.searchCondition.month
      }
      if (this.searchCondition.dept_id) {
        this.$confirm(
            `确定发布【${this.searchCondition.dept_name},${this.searchCondition.year}年${numberToChinese(this.searchCondition.month)}月】奖金吗？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
          // // releaseTargetData
          let id = await this.$api.releasePrizeInfoGroup(data)
          if (!id) {
            this.$message.error('发布失败')
          } else {
            this.$message.success('发布成功')
            await this.getList()
          }
        })
      } else {
        this.$message.error('暂无项目组')
      }
    },
    async handleTarget() {
      let data = {kpi_dept_id: this.dataList[0].kpi_dept_id}
      if (this.searchCondition.dept_id) {
        this.$confirm(
            `确定【${this.searchCondition.dept_name},${this.searchCondition.year}年${numberToChinese(this.searchCondition.month)}月】的目标吗？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
          let id = await this.$api.confirmBonus(data)
          if (!id) {
            this.$message.error('失败,请联系管理员')
          } else {
            this.$message.success('确认成功！')
            await this.getList()

          }
        })
      } else {
        this.$message.error('暂无项目组')
      }
    },
    handleChangeDept(obj) {
      this.searchCondition.dept_id = obj.dept_id || null
      this.searchCondition.dept_name = obj.dept_name || ''
      this.kpi_dept_id = obj.kpi_dept_id
      if (this.searchCondition.dept_id)
      this.getList()
    },
    formatter(row, value) {
      return (`￥${this.valFormat(Number(row[value.property]))}`)
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
}

.tips {
  margin-top: 10px;
}

//table, th, td {
//  border: 1px solid black;
//  border-collapse: collapse; /* 移除单元格间的间隔 */
//}

table {
  border: 1px solid black;

  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

/* 设置不同的背景色 */
.header1 {
  background-color: #f2a6a6; /* 你可以修改为任何你喜欢的颜色 */
}

.header2 {
  background-color: #a6d6f2; /* 你可以修改为任何你喜欢的颜色 */
}

/* 隔列换背景色 */
tr:nth-child(even) td:nth-child(odd),
tr:nth-child(odd) td:nth-child(even) {
  background-color: #f2f2f2;
}

.radius_bg {
  background-color: #FFFFFF;
  margin-top: 10px;
  padding: 0;
  border-radius: 10px

}
</style>
