<template>
  <el-select v-model="v" filterable remote reserve-keyword :placeholder="placeholder" :remote-method="getOptions"
             v-loading="loading" clearable>
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      <span style="float: left">{{ item.label }}</span>
    </el-option>
  </el-select>
</template>
<script>

export default {
  name: 'SelectArtist',
  props: {
    placeholder: {
      type: String,
      default() {
        return '选择红人'
      }
    },
    value: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    label: {
      type: [String, Number],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      v: null,
      options: [],
      loading: false
    }
  },
  watch: {
    v(_) {
      this.updateData(_)
    },
    value() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function() {
      this.v = this.value

      await this.getOptions(this.label)
      if (this.value){
        this.updateData(this.value)
      }
    },
    updateData: function(_) {
      const obj = this.options.find(option => {
        return option.value === _
      })
      this.$emit('update:label', obj ? obj.label : null)
      this.$emit('update:value', _)
      this.$emit('change', obj || false)
    },
    getOptions: async function(keyword) {
      const { list: data } = await this.$api.searchArtists({ nickname: keyword })
      data.map(datum => {
        datum.value = datum.id
        datum.label = datum.nickname
      })
      this.options = data || []
    }
  }
}
</script>
<style lang="scss">

</style>
